import {
  Alert,
  Autocomplete,
  Box,
  Button,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  ListSubheader,
  MenuItem,
  Paper,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ReplyIcon from "@mui/icons-material/Reply";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import Lottie from "lottie-web";
import loadingwait from "../components/animations/loading.json";
import { detaildomaines } from "../components/liste";
import { NumericFormat } from "react-number-format";
import Cookies from "js-cookie";
import axios from "axios";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import * as dayjs from "dayjs";
import SaveIcon from "@mui/icons-material/Save";
import { setDate } from "date-fns";
import CustomDialog from "../components/CustomModal";
import TiptapEditor from "../components/TipTap";

const ModifCertification = () => {
  dayjs.locale("fr");

  const { id } = useParams();
  var cc = require("currency-codes");
  const token = Cookies.get("authToken");
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState();
  const [success, setSuccess] = useState();
  const [openSnack, setOpenSnack] = useState(false);
  const [errors, setErrors] = useState();
  const [errorExist, setReerorExist] = useState();
  const [reloadData, setReloadData] = useState(false);

  //datas
  const [certification, setCertification] = useState();
  const [monnaie, setMonnaie] = useState();
  const [coutCertification, setCoutCertification] = useState();
  const [mensualite, setMensualite] = useState();
  const [apprenant, setApprenant] = useState();
  const [apprenants, setApprenants] = useState([]);

  const [nb_mensualite, setNbmensualite] = useState();
  const [percent, setPercent] = useState();
  const [libelle, setLibelle] = useState();

  const [nb_mensualiteu, setNbmensualiteu] = useState();
  const [percentu, setPercentu] = useState();
  const [libelleu, setLibelleu] = useState();

  const [editingOffer, setEditingOffer] = useState(null);

  const [fonction, setFonction] = useState();
  const [domaine, setDomaine] = useState();
  const [programme, setProgramme] = useState();
  const [number, setNumber] = useState();
  const [niveau, setNiveau] = useState();
  const [code, setCode] = useState();

  //refs
  const loadingref = useRef();
  const res = useRef();
  const refme = useRef();
  const refmo = useRef();
  const refcc = useRef();
  const reff = useRef();
  const refdo = useRef();
  const refpr = useRef();
  const refnu = useRef();
  const refni = useRef();
  const refcode = useRef();
  const refap = useRef();

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const [openDialog2, setOpenDialog2] = useState(false);

  const handleOpenUpdate = () => {
    setOpenDialog2(true);
  };
  const handleCloseUpdate = () => {
    setOpenDialog2(false);
  };

  const action = (
    <Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}admin/certification/edit/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const datas = response.data;
        setCertification(datas.certification);
        setRoles(datas.roles);
        if (datas) {
          setMonnaie(JSON.parse(datas.certification.devise));
          setCoutCertification(datas.certification.cout_certif);
          setFonction(datas.certification.role);
          setDomaine(datas.certification.domaine);
          setProgramme(datas.certification.programme);
          setNumber(datas.certification.number_hour);
          setNiveau(datas.certification.niveau);
          setMensualite(datas.certification.nb_mensualite);
          setCode(datas.certification.code);
          setApprenant(datas.certification.type_apprenant.id);
          setApprenants(datas.apprenants);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [reloadData]);

  useEffect(() => {
    const animation = Lottie.loadAnimation({
      container: loadingref.current,
      animationData: loadingwait,
      renderer: "svg",
      loop: true,
      autoplay: true,
    });
    return () => {
      animation.destroy();
    };
  }, []);

  const focusOnError = (errors, refs) => {
    for (const field in errors) {
      if (errors[field] && refs[field] && refs[field].current) {
        refs[field].current.focus({ behavior: "smooth" });
        break;
      }
    }
  };

  const handleSelectOffer = (offer) => {
    setEditingOffer(offer);
    setNbmensualiteu(offer.nb_mensualite);
    setLibelleu(offer.libelle);
    setPercentu(offer.percent);
  };

  const handleAddOffer = async () => {
    if (!nb_mensualite || !percent || !libelle) {
      setSuccess();
      setReerorExist("Veuillez remplir les différents champs");
      setOpenSnack(true);
    } else {
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}admin/certification/offres/store`,
          {
            libelle,

            nb_mensualite,
            percent,
            certification_id: id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data.success) {
          setReloadData((prev) => !prev);
          setOpenDialog2(false);
          setNbmensualite();
          setPercent();

          setLibelle();
          setReerorExist();
          setSuccess(response.data.message);
          setTimeout(() => {
            setOpenSnack(true);
          }, 50);
        } else {
          setReerorExist(response.data.message);
          setOpenSnack(true);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleState = async (id, statut) => {
    let changeConfirm = window.confirm(
      `Etes vous sûr de vouloir ${
        statut == 1 ? "inactiver" : "activer"
      }  cette offre`
    );
    if (changeConfirm) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}admin/certification/offre/state`,
          {
            id,
            statut,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data.success) {
          setReloadData((prev) => !prev);
          setSuccess(response.data.message);
          setTimeout(() => {
            setOpenSnack(true);
          }, 50);
        } else {
          setReerorExist("Une erreur est survenue");
          setOpenSnack(true);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    } else return;
  };

  const handleDelete = async (id) => {
    let deleteConfirm = window.confirm(
      "Etes vous sûr de vouloir supprimer cette offre?"
    );
    if (deleteConfirm) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_BASE_URL}admin/certification/offre/delete/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data.success) {
          setReloadData((prev) => !prev);
          setSuccess(response.data.message);
          setTimeout(() => {
            setOpenSnack(true);
          }, 50);
        } else {
          setReerorExist("Une erreur est survenue");
          setOpenSnack(true);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    } else return;
  };

  const handleEditOffer = async (id) => {
    if (!nb_mensualiteu || !percentu || !libelleu) {
      setSuccess();
      setReerorExist("Veuillez remplir les différents champs");
      setOpenSnack(true);
    } else {
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}admin/certification/offres/edit/${id}`,
          {
            libelleu,

            nb_mensualiteu,
            percentu,
            _method: "PUT",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data.success) {
          setReloadData((prev) => !prev);
          setNbmensualiteu();
          setPercentu();

          setLibelleu();
          setReerorExist();
          setEditingOffer(null);
          setSuccess(response.data.message);
          setTimeout(() => {
            setOpenSnack(true);
          }, 50);
        } else {
          setReerorExist(response.data.message);
          setOpenSnack(true);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleUpdateEntreprise = async (id) => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}admin/certification/update/${id}`,
        {
          monnaie,
          coutCertification,
          fonction: fonction && fonction.id,
          domaine,
          programme,
          number,
          niveau,
          mensualite,
          apprenant,
          code,
          _method: "PUT",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.success == true) {
        setReloadData((prev) => !prev);
        setErrors();
        setSuccess(response.data.message);
        setTimeout(() => {
          setOpenSnack(true);
        }, 50);
      } else {
        setSuccess(null);
        focusOnError(response.data.errors, {
          monnaie: refmo,
          coutCertification: refcc,
          fonction: reff,
          domaine: refdo,
          programme: refpr,
          number: refnu,
          niveau: refni,
        });
        setErrors(response.data.errors);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const Reinitialiser = () => {
    setLoading(true);
    setReloadData((prev) => !prev);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  const [section, setSection] = useState();
  const [content, setContent] = useState();
  const [type, seType] = useState();
  const [loading2, setloading2] = useState();
  const [openSection, setOpenSection] = useState(false);

  
  const handleSubmit = async () => {
    setloading2(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}admin/certification/update-extra/${id}`,
        {
          content,
          type,
          _method: "PUT",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.success == true) {
        setReloadData((prev) => !prev);
        setOpenSection(false);
        setErrors();
        setSuccess(response.data.message);
        setTimeout(() => {
          setOpenSnack(true);
        }, 50);
      } else {
        setSuccess(null);
        setErrors(response.data.errors);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setloading2(false);
    }
  };
  

  const sections = [
    {
      headerTitle: "Modification du titre principal de la certification",
      buttonTitle: "Titre principal",
     
      type: "main",
    },
    {
      headerTitle: "Modification du titre secondaire de la certification",
      buttonTitle: "Titre secondaire",
      
      type: "sec",
    },
    {
      headerTitle: "Modification de l'image principale de la certification",
      buttonTitle: "Image principale",
      
      type: "img",
    },
    {
      headerTitle: "Modification de la description de la certification",
      buttonTitle: "Description",
      
      type: "desc",
    },
    {
      headerTitle: "Modification des objectifs de la certification",
      buttonTitle: "Objectifs de la formation",
      
      type: "obj",
    },
    {
      headerTitle: "Modification de la durée de la certification",
      buttonTitle: "Durée",
      
      type: "dur",
    },
    {
      headerTitle: "Modification du public de la certification",
      buttonTitle: "Public cibles",
      
      type: "pub",
    },
    {
      headerTitle: "Modification des méthodes pédagogiques de la certification",
      buttonTitle: "Méthodes pédagogiques",
     
      type: "meth",
    },
    {
      headerTitle: "Modification des modalités de la certification",
      buttonTitle: "Modalités",
      
      type: "mod",
    },
    {
      headerTitle: "Modification des préréquis de la certification",
      buttonTitle: "Préréquis",
      
      type: "req",
    },
    {
      headerTitle: "Modification des formateurs de la certification",
      buttonTitle: "Formateurs",
      
      type: "form",
    },
    {
      headerTitle: "Modification des evaluations de la certification",
      buttonTitle: "Évaluations et certification",
      
      type: "eval",
    },
    {
      headerTitle: "Modification des débouchés de la certification",
      buttonTitle: "Débouchés",
      
      type: "deb",
    },
    {
      headerTitle:
        "Modification des domaines d'intervention de la certification",
      buttonTitle: "Domaines d'intervention",
      
      type: "dom",
    },
    {
      headerTitle: "Modification des compétences acquises de la certification",
      buttonTitle: "Compétences clés acquises",
     
      type: "comp",
    },
    {
      headerTitle: "Modification des responsibiltés après la certification",
      buttonTitle: "Responsabilités",
     
      type: "rsp",
    },
    {
      headerTitle: "Modification de la vidéo explicative de la certification",
      buttonTitle: "Vidéo explicative",
      
      type: "video",
    },
    {
      headerTitle: "Modification de la phrase d'accroche de la certification",
      buttonTitle: "Phrase d'accroche",
     
      type: "phra",
    },
  ];

  const chooSection = async (data) => {
    const typeToField = {
      main: "main_title",
      sec: "secondary_title",
      img: "main_image",
      desc: "description",
      obj: "objectives",
      dur: "duration",
      pub: "target_person",
      meth: "methodes",
      mod: "modalites",
      req: "prerequis",
      form: "trainers",
      eval: "evaluations",
      deb: "opportunities",
      dom: "domaines_intervention",
      comp: "competences",
      rsp: "responsabiliies",
      video: "video",
      phra: "catchphrase",
    };

    const type = data.type;

    const field = typeToField[type];
   setContent(certification[field]);
    setSection(data);
    seType(data.type);
    setOpenSection(true);
  };
  

  return (
    <AnimatePresence>
      {success ? (
        <Snackbar
          open={openSnack}
          autoHideDuration={5000}
          onClose={handleCloseSnack}
          action={action}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
          >
            {success}
          </Alert>
        </Snackbar>
      ) : (
        errorExist && (
          <Snackbar
            open={openSnack}
            onClose={handleCloseSnack}
            action={action}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert
              onClose={handleCloseSnack}
              severity="error"
              sx={{ width: "100%" }}
            >
              {errorExist}
            </Alert>
          </Snackbar>
        )
      )}
      {certification && roles ? (
        <motion.div
          className="mb-3"
          // initial={{ opacity: 0 }}
          // animate={{ opacity: 1 }}
          // exit={{ opacity: 0 }}
          // transition={{ duration: 0.2, ease: "easeInOut" }}
        >
          <Paper elevation={1} className="p-3">
            <CardHeader
              subheader={`Modification des informations de certification`}
              action={
                <>
                  <Tooltip title="Retourner à la liste">
                    <Link to={`/dashboard/certifications`}>
                      <IconButton aria-label="settings">
                        <ReplyIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                  <Tooltip title="Ajouter une offre">
                    <IconButton
                      onClick={handleOpenUpdate}
                      aria-label="settings"
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </>
              }
            />
            <Divider />
            <br />
            <Box>
              <form
                ref={res}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleUpdateEntreprise(id);
                }}
              >
                <Grid container spacing={2} className="pt-3">
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <Autocomplete
                        id="country-select-demo2"
                        onChange={(event, newValue) => {
                          setFonction(newValue && newValue);
                        }}
                        value={fonction}
                        options={roles}
                        autoHighlight
                        fullWidth
                        getOptionLabel={(option) =>
                          option ? option.title : ""
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Choisir la fonction"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                            ref={reff}
                            error={errors && errors.fonction}
                            helperText={
                              errors &&
                              errors.fonction &&
                              errors.fonction.join(", ")
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    tabIndex={-1}
                                                    ref={refdo}
                                                    select
                                                    error={errors && errors.domaine}
                                                    helperText={
                                                        errors && errors.domaine && errors.domaine.join(', ')
                                                    }
                                                    fullWidth
                                                    value={domaine}
                                                    label="Domaine"
                                                    onChange={(e) => setDomaine(e.target.value)}
                                                >
                                                    <MenuItem value="">...</MenuItem>
                                                    {
                                                        domaines.map((domaine) => {
                                                            return (
                                                                <MenuItem value={domaine}>{domaine}</MenuItem>
                                                            )
                                                        })
                                                    }
                                                </TextField>
                                            </FormControl>

                                        </Grid> */}
                  <Grid item xs={12} sm={12} md={3}>
                    <FormControl fullWidth>
                      <TextField
                        tabIndex={-1}
                        ref={refdo}
                        select
                        error={errors && errors.domaine}
                        helperText={
                          errors && errors.domaine && errors.domaine.join(", ")
                        }
                        fullWidth
                        value={domaine}
                        label="Domaine"
                        onChange={(e) => setDomaine(e.target.value)}
                      >
                        <MenuItem value="">...</MenuItem>
                        {detaildomaines.map((domaine) => {
                          return (
                            <MenuItem value={domaine.value}>
                              {domaine.label} ({domaine.value})
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <FormControl fullWidth>
                      <TextField
                        tabIndex={-1}
                        ref={refap}
                        select
                        error={errors && errors.apprenant}
                        helperText={
                          errors &&
                          errors.apprenant &&
                          errors.apprenant.join(", ")
                        }
                        fullWidth
                        value={apprenant}
                        label="Catégorie d'apprenant"
                        onChange={(e) => setApprenant(e.target.value)}
                      >
                        <MenuItem value="">...</MenuItem>
                        {apprenants.map((apprenant) => {
                          return (
                            <MenuItem value={apprenant.id}>
                              {apprenant.libelle}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        tabIndex={-1}
                        ref={refpr}
                        error={errors && errors.programme}
                        helperText={
                          errors &&
                          errors.programme &&
                          errors.programme.join(", ")
                        }
                        fullWidth
                        value={programme}
                        onChange={(e) => setProgramme(e.target.value)}
                        label="Programme"
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <TextField
                            tabIndex={-1}
                            ref={refnu}
                            type="number"
                            error={errors && errors.number}
                            helperText={
                              errors &&
                              errors.number &&
                              errors.number.join(", ")
                            }
                            fullWidth
                            value={number}
                            onChange={(e) => setNumber(e.target.value)}
                            label="Nombre d'heure"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <TextField
                            tabIndex={-1}
                            ref={refni}
                            select
                            error={errors && errors.niveau}
                            helperText={
                              errors &&
                              errors.niveau &&
                              errors.niveau.join(", ")
                            }
                            fullWidth
                            value={niveau}
                            onChange={(e) => {
                              setNiveau(e.target.value);
                            }}
                            label="Niveau"
                          >
                            <MenuItem value="">...</MenuItem>
                            <ListSubheader>Débutant</ListSubheader>
                            <MenuItem value="Débutant (1)">Niveau 1</MenuItem>
                            <MenuItem value="Débutant (2)">Niveau 2</MenuItem>
                            <ListSubheader>Intermédiaire</ListSubheader>
                            <MenuItem value="Intermédiaire (3)">
                              Niveau 3
                            </MenuItem>
                            <MenuItem value="Intermédiaire (4)">
                              Niveau 4
                            </MenuItem>
                            <ListSubheader>Compétent</ListSubheader>
                            <MenuItem value="Compétent (5)">Niveau 5</MenuItem>
                            <MenuItem value="Compétent (6)">Niveau 6</MenuItem>
                            <ListSubheader>Avancé</ListSubheader>
                            <MenuItem value="Avancé (7)">Niveau 7</MenuItem>
                            <MenuItem value="Avancé (8)">Niveau 8</MenuItem>
                            <ListSubheader>Expert</ListSubheader>
                            <MenuItem value="Expert (9)">Niveau 9</MenuItem>
                            <MenuItem value="Expert (10)">Niveau 10</MenuItem>
                          </TextField>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <Autocomplete
                        id="country-select-demo2"
                        onChange={(event, newValue) => {
                          setMonnaie(newValue && newValue);
                        }}
                        value={monnaie}
                        options={cc.data}
                        autoHighlight
                        fullWidth
                        getOptionLabel={(option) =>
                          option ? option.currency : ""
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Choisir la devise"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                            ref={refmo}
                            error={errors && errors.monnaie}
                            helperText={
                              errors &&
                              errors.monnaie &&
                              errors.monnaie.join(", ")
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <NumericFormat
                        tabIndex={-1}
                        ref={refcc}
                        label="Cout de la certification"
                        error={errors && errors.coutCertification}
                        helperText={
                          errors &&
                          errors.coutCertification &&
                          errors.coutCertification.join(", ")
                        }
                        fixedDecimalScale
                        allowLeadingZeros
                        thousandSeparator=","
                        customInput={TextField}
                        variant="outlined"
                        value={coutCertification}
                        onValueChange={(value) =>
                          setCoutCertification(
                            value !== "undefined" &&
                              value.floatValue !== "undefined"
                              ? value.floatValue
                              : ""
                          )
                        }
                        allowNegative={false}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {monnaie && monnaie !== ""
                                ? monnaie.code
                                : "Devise"}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <NumericFormat
                        tabIndex={-1}
                        ref={refme}
                        label="Mensualité"
                        error={errors && errors.mensualite}
                        helperText={
                          errors &&
                          errors.mensualite &&
                          errors.mensualite.join(", ")
                        }
                        fixedDecimalScale
                        allowLeadingZeros
                        thousandSeparator=","
                        customInput={TextField}
                        variant="outlined"
                        value={mensualite}
                        onValueChange={(value) =>
                          setMensualite(
                            value !== "undefined" &&
                              value.floatValue !== "undefined"
                              ? value.floatValue
                              : ""
                          )
                        }
                        allowNegative={false}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {monnaie && monnaie !== ""
                                ? monnaie.code
                                : "Devise"}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        tabIndex={-1}
                        ref={refcode}
                        error={errors && errors.code}
                        helperText={
                          errors && errors.code && errors.code.join(", ")
                        }
                        fullWidth
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        label="Code"
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Box
                  mt={3}
                  gap={2}
                  display={"flex"}
                  justifyContent={"flex-end"}
                >
                  <LoadingButton
                    loading={loading}
                    variant="outlined"
                    type="submit"
                  >
                    Enregistrer
                  </LoadingButton>
                  {!loading && (
                    <Button onClick={Reinitialiser} variant="outlined">
                      Reinitialiser
                    </Button>
                  )}
                </Box>
              </form>
            </Box>

            <Box mt={5} />
            <CardHeader subheader={`Informations de la plateforme CYVAN`} />
            <Divider />

            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={3}
              flexWrap={"wrap"}
            >
              {sections.map((section, index) => (
                <Button
                  key={index}
                  variant="outlined"
                  onClick={() => chooSection(section)}
                >
                  {section.buttonTitle}
                </Button>
              ))}
            </Box>

            {/* dialog */}
            {section && (
              <CustomDialog
                open={openSection}
                headerTitle={section.headerTitle}
                onClose={() => setOpenSection(false)}
                handleSubmit={handleSubmit}
                content={content}
                setContent={setContent}
                loading={loading2}
                isVideo={section.type=="video"}
                isImage={section.type=="img"}
              />
            )}
            {/* dialog */}

            <Box mt={5} />
            <CardHeader subheader={`Offres Spéciales`} />
            <Divider />

            <Grid container spacing={2}>
              {certification.mensualite_certifications.map((offer) => (
                <>
                  <Grid item xs={3} md={3}>
                    <FormControl fullWidth>
                      <TextField
                        tabIndex={-1}
                        fullWidth
                        value={
                          editingOffer && editingOffer.id == offer.id
                            ? libelleu
                            : offer.libelle
                        }
                        onChange={(e) => setLibelleu(e.target.value)}
                        label="Libéllé de l'offre"
                        variant="outlined"
                        disabled={
                          editingOffer && editingOffer.id == offer.id
                            ? false
                            : true
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <NumericFormat
                        tabIndex={-1}
                        label="Mensualité"
                        fixedDecimalScale
                        allowLeadingZeros
                        thousandSeparator=","
                        customInput={TextField}
                        variant="outlined"
                        value={offer.nb_mensualite}
                        onValueChange={(value) =>
                          setNbmensualiteu(
                            value !== "undefined" &&
                              value.floatValue !== "undefined"
                              ? value.floatValue
                              : ""
                          )
                        }
                        allowNegative={false}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {monnaie && monnaie !== ""
                                ? monnaie.code
                                : "Devise"}
                            </InputAdornment>
                          ),
                        }}
                        disabled={
                          editingOffer && editingOffer.id == offer.id
                            ? false
                            : true
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <NumericFormat
                        tabIndex={-1}
                        label="Pourcentage de réduction"
                        fixedDecimalScale
                        allowLeadingZeros
                        thousandSeparator=","
                        customInput={TextField}
                        variant="outlined"
                        value={offer.percent}
                        onValueChange={(value) =>
                          setPercentu(
                            value !== "undefined" &&
                              value.floatValue !== "undefined"
                              ? value.floatValue
                              : ""
                          )
                        }
                        allowNegative={false}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                        disabled={
                          editingOffer && editingOffer.id == offer.id
                            ? false
                            : true
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    xs={3}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    {editingOffer && editingOffer.id == offer.id ? (
                      <Tooltip title="Enregistrer les modifications">
                        <IconButton
                          onClick={() => handleEditOffer(offer.id)}
                          aria-label="settings"
                        >
                          <SaveIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Modifier">
                        <IconButton onClick={() => handleSelectOffer(offer)}>
                          <ModeEditOutlineIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                    )}

                    <Tooltip
                      title={
                        offer.statut
                          ? "Actuellement actif"
                          : "Actuellement inactif"
                      }
                    >
                      <IconButton
                        onClick={() => handleState(offer.id, offer.statut)}
                        aria-label="settings"
                      >
                        {offer.statut ? (
                          <LockOpenIcon color="success" />
                        ) : (
                          <LockIcon color="error" />
                        )}
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Supprimer">
                      <IconButton
                        onClick={() => handleDelete(offer.id)}
                        aria-label="settings"
                      >
                        <DeleteOutlineIcon color="error" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <hr />
                </>
              ))}
            </Grid>
            <br />
            <Stack
              direction="row"
              spacing={2}
              className="d-flex justify-content-end"
            ></Stack>
          </Paper>

          {/* dialog d ajout doffre*/}
          <Dialog open={openDialog2} onClose={handleCloseUpdate}>
            <DialogTitle>Ajout d'une offre</DialogTitle>
            <DialogContent>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      tabIndex={-1}
                      fullWidth
                      value={libelle}
                      onChange={(e) => setLibelle(e.target.value)}
                      label="Libelle de l'offre"
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <NumericFormat
                      tabIndex={-1}
                      label="Mensualité"
                      fixedDecimalScale
                      allowLeadingZeros
                      thousandSeparator=","
                      customInput={TextField}
                      variant="outlined"
                      value={nb_mensualite}
                      onValueChange={(value) =>
                        setNbmensualite(
                          value !== "undefined" &&
                            value.floatValue !== "undefined"
                            ? value.floatValue
                            : ""
                        )
                      }
                      allowNegative={false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <NumericFormat
                      tabIndex={-1}
                      label="Pourcentage de réduction"
                      fixedDecimalScale
                      allowLeadingZeros
                      thousandSeparator=","
                      customInput={TextField}
                      variant="outlined"
                      value={percent}
                      onValueChange={(value) =>
                        setPercent(
                          value !== "undefined" &&
                            value.floatValue !== "undefined"
                            ? value.floatValue
                            : ""
                        )
                      }
                      allowNegative={false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseUpdate}>Annuler</Button>
              <LoadingButton
                onClick={handleAddOffer}
                loading={loading}
                variant="outlined"
                type="submit"
              >
                Valider
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </motion.div>
      ) : (
        <div
          className="text-center d-flex justify-content-center align-items-center"
          style={{ minHeight: "65vh" }}
        >
          <div
            style={{ maxHeight: "100px", maxWidth: "100px" }}
            ref={loadingref}
          ></div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default ModifCertification;
