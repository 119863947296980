import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import TiptapEditor from "../components/TipTap";
import ImageEditor from './ImageEditor';
import VideoEditor from './VideoEditor';


interface DialogProps {
  open: boolean;
  onClose: () => void;
  headerTitle: string;
  content: any;
  handleSubmit: () => void;
  loading: boolean,
  setContent: (content: any) => void;
  isVideo?: boolean,
  isImage?: boolean,

}

export default function CustomDialog({ open, onClose, headerTitle, content, handleSubmit, loading, setContent, isVideo, isImage }: DialogProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleGetContent = (content: any) => {
    setContent(content);
  };
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      maxWidth={'lg'}
      
    >
      <DialogTitle id="responsive-dialog-title">
        {headerTitle}
      </DialogTitle>
      <DialogContent>
        {
          isVideo ? (
            <VideoEditor content={content} handleChange={handleGetContent} />
          ) : isImage ? (
            <ImageEditor content={content} handleChange={handleGetContent} />
          ) : (
            <TiptapEditor content={content} handleChange={handleGetContent} />
          )
        }

      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          Fermer
        </Button>
        <LoadingButton
          onClick={handleSubmit}
          loading={loading}
          variant="outlined"
        >
          Enregistrer
        </LoadingButton>

      </DialogActions>
    </Dialog>
  );
}
