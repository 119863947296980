import { Box, Button } from "@mui/material";
import { useState } from "react";

export default function ImageEditor({ content, handleChange }) {
  const [preview, setPreview] = useState();

  const handleFileUpload = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    const file = e.target.files[0];

    // Vérifie si c'est bien une image
    if (!file.type.startsWith("image/")) {
      alert("Veuillez sélectionner une image !");
      return;
    }

    // Met à jour l'aperçu
    setPreview(URL.createObjectURL(file));
    
    handleChange(file);

    // Lecture du fichier si nécessaire
    const reader = new FileReader();
    reader.onload = (evt) => {
      if (!evt?.target?.result) {
        return;
      }
      const { result } = evt.target;
    };
    reader.readAsDataURL(file);
  };

  return (
    <Box width={"100%"} display={"flex"} flexDirection={"column"} gap={5} paddingTop={5}>
      <Button variant="outlined" fullWidth component="label">
        UPLOADER L'IMAGE
        <input
          type="file"
          accept="image/*"
          hidden
          onChange={handleFileUpload}
        />
      </Button>

      <Box width={"100%"} height={300} bgcolor={"#f9f9f9"}>
        <img
          style={{ height: "100%",width:"100%",  objectFit: "cover" }}
          src={preview ? preview : content ?  (process.env.REACT_APP_BASE_URL+content) : "/images/noimage.jpeg"}

        />
      </Box>
    </Box>
  );
}
