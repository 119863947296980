import { Box, FormControl, TextField } from "@mui/material";


export default function VideoEditor({ content, handleChange }) {

  const isValidUrl = (url) => {
    const regex =
      /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d{1,5})?(\/.*)?$/;
    return regex.test(url);
  };
  return (
    <Box display={"flex"} flexDirection={"column"} gap={5} paddingTop={5}>
      <FormControl fullWidth>
        <TextField
          tabIndex={-1}
          fullWidth
          value={content}
          onChange={(e) => handleChange(e.target.value)}
          label="Url de la video"
          variant="outlined"
        />
      </FormControl>

      <iframe
        style={{ width: "100%", minHeight: 300, backgroundColor: "#f9f9f9" }}
        src={isValidUrl(content)? content:''}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </Box>
  );
}
