import React, { useState, useEffect, useRef, createRef } from "react";
import {
  Box,
  Card,
  Grid,
  IconButton,
  Chip,
  Typography,
  CardContent,
  LinearProgress,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
} from "@mui/material";
import "dayjs/locale/fr";
import EditIcon from "@mui/icons-material/Edit";
import BlindsIcon from "@mui/icons-material/Blinds";
import PrintIcon from "@mui/icons-material/Print";
import { Link } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import axios from "axios";
import Cookies from "js-cookie";
import { toast, ToastContainer } from "react-toastify";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import * as dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const Certification = ({ certification, editDiplome, setReload, id_user }) => {
  const [editDip, setEditDip] = useState(false);
  const [createModal, setCreateModal] = useState(false);

  const [diplome, setIdDiplome] = useState(null);
  const [debut, setDebut] = useState(null);

  const [user, setIduser] = useState(null);
  const [certification_id, setCertificationid] = useState(null);
  const [pending, setPending] = useState(null);

  const token = Cookies.get("authToken");

  useEffect(() => {
    if (certification) {
      setIduser(id_user);
      setCertificationid(certification?.certification.id);
      setPending(certification.pending);
    }
  }, []);

  const handleClickEditDip = (id) => {
    setIdDiplome(id ?? null);
    setEditDip(true);
  };

  const handleCloseDip = () => {
    setEditDip(false);
    setIdDiplome(null);
  };

  const handleClickCreate = (date) => {
    setDebut(date ?? null);
    setCreateModal(true);
  };

  const handleCloseCreate = () => {
    setCreateModal(false);
    setDebut(null);
  };

  const exportInfos = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}admin/statut-certif`,
        {
          id: diplome,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
          responseType: "blob",
        }
      );
      setReload((prevState) => !prevState);
      toast.success("Modification realisée avec succès!");
      setEditDip(false);
    } catch (error) {
      console.log(error);
      toast.error("Erreur lors de la modification");
    }
  };

  const handleEditDiploma = async () => {
    toast
      .promise(exportInfos(), {
        pending: "Application en cours...",
      })
      .then(() => {})
      .catch((error) => {
        console.error("Erreur lors de la modification des informations", error);
      });
  };

  const createDiplome = async () => {
    if (debut) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}admin/users/certification/create-diplome`,
          {
            debut: debut,
            id_user: user,
            certification_id: certification_id,
            pending: pending,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setCreateModal(false);
        setReload((prevState) => !prevState);
        toast.success("Diplôme créé avec succès!");
      } catch (error) {
        console.log(error);
        toast.error("Erreur lors de la modification");
      }
    }
  };

  const handleCreateDiplome = (certification) => {
    toast
      .promise(createDiplome(certification), {
        pending: "Création du diplôme en cours...",
      })
      .then(() => {})
      .catch((error) => {
        console.error("Erreur lors de la creation", error);
      });
  };
  console.log(debut);

  return (
    <Grid item xs={12} sm={6}>
      <Card>
        <CardContent>
          <>
            <Chip
              icon={<BlindsIcon />}
              label="CERTIFICATION CYVAN"
              variant="outlined"
              color="primary"
            />
            <Box className="text-capitalize text-center my-4" sx={{}}>
              {certification.certification.programme}
            </Box>
            <Grid
              container
              spacing={3}
              direction="row"
              className="mb-5 d-flex justify-content-center align-items-center"
            >
              <Grid item xs={6} sm={4}>
                <Card className="p-2 d-flex  justify-content-center align-items-center">
                  <Box className=" d-flex flex-column gap-2  ">
                    <span style={{ fontSize: ".80em", color: "#8A92A6" }}>
                      Domaine
                    </span>
                    <span style={{ fontSize: ".9em", fontWeight: "550" }}>
                      {certification.certification.domaine}
                    </span>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Card className="p-2 d-flex  justify-content-center align-items-center">
                  <Box className=" d-flex flex-column gap-2  ">
                    <span style={{ fontSize: ".80em", color: "#8A92A6" }}>
                      Niveau
                    </span>
                    <span style={{ fontSize: ".9em", fontWeight: "550" }}>
                      {certification.certification.niveau}
                    </span>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Card className="p-2 d-flex  justify-content-center align-items-center">
                  <Box className=" d-flex flex-column gap-2  ">
                    <span style={{ fontSize: ".80em", color: "#8A92A6" }}>
                      Cout
                    </span>
                    <span
                      style={{ fontSize: ".9em", fontWeight: "550" }}
                    >{`${certification.price
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${
                      JSON.parse(certification.certification.devise).currency
                    }`}</span>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Card className="p-2 d-flex  justify-content-center align-items-center">
                  <Box className=" d-flex flex-column gap-2  ">
                    <span style={{ fontSize: ".80em", color: "#8A92A6" }}>
                      Soldé
                    </span>
                    <span
                      style={{ fontSize: ".9em", fontWeight: "550" }}
                    >{`${certification.solded
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${
                      JSON.parse(certification.certification.devise).currency
                    }`}</span>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Card className="p-2 d-flex  justify-content-center align-items-center">
                  <Box className=" d-flex flex-column gap-2  ">
                    <span style={{ fontSize: ".80em", color: "#8A92A6" }}>
                      Reste
                    </span>
                    <span
                      style={{ fontSize: ".9em", fontWeight: "550" }}
                    >{`${certification.pending
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${
                      JSON.parse(certification.certification.devise).currency
                    }`}</span>
                  </Box>
                </Card>
              </Grid>
            </Grid>
            {certification.solded && (
              <div
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box>
                  <Chip
                    variant="filled"
                    color={
                      certification.statut == "delivered" ? "success" : "error"
                    }
                    label={
                      certification.statut == "delivered" ? "Payé" : certification.statut == "pending"? "en cours" : "Annulé"
                    }
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box sx={{ width: "100%", mr: 1 }}>
                    <LinearProgress
                      variant="determinate"
                      value={
                        (certification.solded / certification.price) * 100
                      }
                    />
                  </Box>
                  <Box
                    sx={{ minWidth: 100 }}
                    className="d-flex flex-column gap-3"
                  >
                    <Typography variant="body2" color="text.secondary">
                      {`${certification.solded
                        .toString()
                        .replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        )}/${certification.price
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                    </Typography>
                  </Box>
                </Box>
              </div>
            )}

            <Box className="d-flex justify-content-between align-items-center gap-4 mt-3">
              {certification?.diplome ? (
                <>
                  <Box className="d-flex align-items-center gap-3">
                    <Tooltip title="Modifier les informations">
                      <IconButton
                        onClick={editDiplome}
                        color="primary"
                        aria-label="add to shopping cart"
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Activer/Désactiver le diplôme">
                      <Box
                        to={`/dashboard/certification/diplome/${certification.diplome?.id}`}
                        color="primary"
                        style={{
                          border:
                            certification.diplome?.statut == 1
                              ? "1px solid green"
                              : "1px solid red",
                          padding: 5,
                          color:
                            certification.diplome?.statut == 1
                              ? "green"
                              : "red",
                        }}
                        aria-label="Imprimer le diplome"
                        role="button"
                        onClick={() =>
                          handleClickEditDip(certification.diplome?.id)
                        }
                      >
                        {certification.diplome.statut == 1
                          ? "Activé"
                          : "Désactivé"}
                      </Box>
                    </Tooltip>
                  </Box>

                  <Tooltip title="Imprimer le diplome">
                    <Link
                      to={`/dashboard/certification/diplome/${certification.diplome?.id}`}
                      color="primary"
                      style={{ border: "1px solid blue", padding: 5 }}
                      aria-label="Imprimer le diplome"
                    >
                      Visualiser
                    </Link>
                  </Tooltip>
                </>
              ) : (
                <Tooltip title="Créer le diplome">
                  <IconButton
                    onClick={handleClickCreate}
                    color="primary"
                    aria-label="add to shopping cart"
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>

            {/* modal */}
            <Dialog onClose={handleCloseDip} open={editDip}>
              <DialogTitle>Statut de diplôme</DialogTitle>
              <DialogContent>
                {certification.diplome?.statut == 1
                  ? "Ce diplôme est actuellement actif, voulez-vous le désactiver?"
                  : "Ce diplôme est actuellement inactif, voulez-vous l'activer?"}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDip}>Annuler</Button>
                <Button onClick={handleEditDiploma}>Valider</Button>
              </DialogActions>
            </Dialog>

            {/* modal de creation de diplome */}
            <Dialog onClose={handleCloseCreate} open={createModal}>
              <DialogTitle>Diplome</DialogTitle>
              <DialogContent>
                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="fr"
                    >
                      <FormControl required fullWidth>
                        <DatePicker
                          value={debut ? dayjs(debut) : null} // Assurez-vous que `debut` est un objet dayjs ou `null`
                          onChange={(newValue) => {
                            // Vérifiez si la valeur est bien un objet dayjs et mettez à jour l'état correctement
                            if (newValue && newValue.isValid()) {
                              setDebut(newValue.format("YYYY-MM-DD")); // Mettre la date formatée dans `debut`
                            } else {
                              setDebut(null); // Si la valeur n'est pas valide, on met `null`
                            }
                          }}
                          label="Date de debut de formation"
                        />
                      </FormControl>
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCreateDiplome} type="submit">
                  Valider
                </Button>
              </DialogActions>
            </Dialog>
          </>
        </CardContent>
      </Card>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={true}
        pauseOnHover={false}
      />
    </Grid>
  );
};

export default Certification;
