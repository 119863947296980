import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import { EditorContent, useEditor } from "@tiptap/react";
import TextAlign from "@tiptap/extension-text-align";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";

import {
  Box,
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import {
  FormatBold,
  FormatItalic,
  FormatStrikethrough,
  FormatListBulleted,
  FormatListNumbered,
  Undo,
  Redo,
  HorizontalRule,
  Title,
  FormatClear,
  
  FormatAlignLeft,
  FormatAlignCenter,
  FormatAlignRight,
  FormatAlignJustify,
  FormatColorText,
} from "@mui/icons-material";
import { useEffect } from "react";


const MenuBar = ({ editor }) => {
  if (!editor) return null;

  return (
    <Stack spacing={1} direction="row" flexWrap="wrap" alignItems="center">
      {/* Bold, Italic, Strike, Code */}
      <ToggleButtonGroup size="small" exclusive>
        <Tooltip title="Gras">
          <ToggleButton
            value="bold"
            selected={editor.isActive("bold")}
            onClick={() => editor.chain().focus().toggleBold().run()}
          >
            <FormatBold />
          </ToggleButton>
        </Tooltip>

        <Tooltip title="Italique">
          <ToggleButton
            value="italic"
            selected={editor.isActive("italic")}
            onClick={() => editor.chain().focus().toggleItalic().run()}
          >
            <FormatItalic />
          </ToggleButton>
        </Tooltip>

        <Tooltip title="Barré">
          <ToggleButton
            value="strike"
            selected={editor.isActive("strike")}
            onClick={() => editor.chain().focus().toggleStrike().run()}
          >
            <FormatStrikethrough />
          </ToggleButton>
        </Tooltip>

        <Tooltip title="Couleur du texte">
          <ToggleButton value="color">
            <FormatColorText />
            <input
              type="color"
              onChange={(e) =>
                editor.chain().focus().setColor(e.target.value).run()
              }
              style={{
                position: "absolute",
                opacity: 0,
                width: "100%",
                height: "100%",
                cursor: "pointer",
              }}
            />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>

      {/* Headings */}
      <ToggleButtonGroup size="small" exclusive>
        {[1, 2, 3].map((level) => (
          <Tooltip key={level} title={`Titre H${level}`}>
            <ToggleButton
              value={`h${level}`}
              selected={editor.isActive("heading", { level })}
              onClick={() =>
                editor.chain().focus().toggleHeading({ level }).run()
              }
            >
              <Title fontSize="small" />H{level}
            </ToggleButton>
          </Tooltip>
        ))}
      </ToggleButtonGroup>

      {/* Text Alignment */}
      <ToggleButtonGroup size="small" exclusive>
        <Tooltip title="Aligner à gauche">
          <ToggleButton
            value="left"
            selected={
              editor.isActive("paragraph") &&
              editor.getAttributes("paragraph").textAlign === "left"
            }
            onClick={() => editor.chain().focus().setTextAlign("left").run()}
          >
            <FormatAlignLeft />
          </ToggleButton>
        </Tooltip>

        <Tooltip title="Centrer">
          <ToggleButton
            value="center"
            selected={
              editor.isActive("paragraph") &&
              editor.getAttributes("paragraph").textAlign === "center"
            }
            onClick={() => editor.chain().focus().setTextAlign("center").run()}
          >
            <FormatAlignCenter />
          </ToggleButton>
        </Tooltip>

        <Tooltip title="Aligner à droite">
          <ToggleButton
            value="right"
            selected={
              editor.isActive("paragraph") &&
              editor.getAttributes("paragraph").textAlign === "right"
            }
            onClick={() => editor.chain().focus().setTextAlign("right").run()}
          >
            <FormatAlignRight />
          </ToggleButton>
        </Tooltip>

        <Tooltip title="Justifier">
          <ToggleButton
            value="justify"
            selected={
              editor.isActive("paragraph") &&
              editor.getAttributes("paragraph").textAlign === "justify"
            }
            onClick={() => editor.chain().focus().setTextAlign("justify").run()}
          >
            <FormatAlignJustify />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>

      {/* Lists */}
      <ToggleButtonGroup size="small" exclusive>
        <Tooltip title="Liste à puces">
          <ToggleButton
            value="bulletList"
            selected={editor.isActive("bulletList")}
            onClick={() => editor.chain().focus().toggleBulletList().run()}
          >
            <FormatListBulleted />
          </ToggleButton>
        </Tooltip>

        <Tooltip title="Liste numérotée">
          <ToggleButton
            value="orderedList"
            selected={editor.isActive("orderedList")}
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
          >
            <FormatListNumbered />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>

      {/* Other Options */}
      <ToggleButtonGroup size="small" exclusive>
        <Tooltip title="Ligne horizontale">
          <IconButton
            size="small"
            onClick={() => editor.chain().focus().setHorizontalRule().run()}
          >
            <HorizontalRule />
          </IconButton>
        </Tooltip>

        <Tooltip title="Annuler">
          <IconButton
            size="small"
            disabled={!editor.can().chain().focus().undo().run()}
            onClick={() => editor.chain().focus().undo().run()}
          >
            <Undo />
          </IconButton>
        </Tooltip>

        <Tooltip title="Rétablir">
          <IconButton
            size="small"
            disabled={!editor.can().chain().focus().redo().run()}
            onClick={() => editor.chain().focus().redo().run()}
          >
            <Redo />
          </IconButton>
        </Tooltip>

        <Tooltip title="Effacer le formatage">
          <IconButton
            size="small"
            onClick={() =>
              editor.chain().focus().clearNodes().unsetAllMarks().run()
            }
          >
            <FormatClear />
          </IconButton>
        </Tooltip>
      </ToggleButtonGroup>
    </Stack>
  );
};

const extensions = [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] }),
  StarterKit.configure({
    bulletList: { keepMarks: true, keepAttributes: false },
    orderedList: { keepMarks: true, keepAttributes: false },
  }),
  Color,
  TextAlign.configure({
    types: ["heading", "paragraph"],
  })
];

export default function TextEditor({ content, handleChange }) {

  const editor = useEditor({
    extensions,
    content,
    onUpdate: ({ editor }) => {
      handleChange(editor.getHTML());
    },
  });

  useEffect(() => {
    if (editor) {
      editor.commands.focus("end");
    }
  }, [editor]);

  return (
    <Stack spacing={3}>
      <MenuBar editor={editor} />
      <Box minHeight={300} bgcolor={"#f9f9f9"} padding={3}>
        <EditorContent editor={editor} />
      </Box>
    </Stack>
  );
}
